import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: grey;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
`;

export const MainWrapper = styled('div')`
  width: 90%;
  ${({ theme }) => theme.mq.md} {
    width: 70%;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  gap: 2.5rem;
`;

export const StyledTitle = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5rem;
  }
`;

export const StyledText = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;
