import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledTitle = styled('h2')`
  font-size: 2.4rem;
  line-height: 3.6rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.green};
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
`;

export const StyledTitelWrapper = styled('div')`
  margin-bottom: 6.5rem;
`;

export const StyledSubtitle = styled('h2')`
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.8rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.grey};
`;

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  position: relative;
`;

export const StyledFormWrapper = styled('div')`
  padding: 10rem;
  ${({ theme }) => theme.mq.lg} {
    padding: 20rem;
  }
`;

export const StyledFormInnerWrapper = styled('div')`
  position: relative;
`;

export const StyledForm = styled('form')`
  width: 100%;
  margin: 0;
  overflow: hidden;
  transition: filter 0.5s;

  ${({ theme }) => theme.mq.smm} {
    border-radius: 5px;
  }
`;

export const StyledInputRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  ${({ theme }) => theme.mq.md} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledSubmitBtn = styled('button')`
  font-family: 'Montserrat', serif;
  width: 100%;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

export const StyledInputsWrapper = styled('div')`
  padding: 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 0 4rem;
  }
`;
