import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-intl';

export const StyledTwoProducts = styled('div')`
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
`;

export const StyledContainer = styled('div')`
  max-width: 600px;
  margin: 0 auto;
  padding: 3.8rem 2.5rem 6rem;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
  ${({ theme }) => theme.mq.sm} {
    padding: 3.8rem 2.5rem 6rem;
  }
  ${({ theme }) => theme.mq.smm} {
    padding: 3.8rem 2.5rem 10rem;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 12rem 2.5rem 8rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 12rem 2.5rem 11rem;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 3.8rem 2.5rem 3rem;
  overflow: hidden;
  width: 100%;
  ${({ theme }) => theme.mq.lg} {
    padding: 3.8rem 2.5rem 6rem;
    flex-direction: row;
    > div:last-child {
      flex-direction: row-reverse;
    }
  }
`;

export const StyledContent = styled('div')`
  color: ${({ theme }) => theme.colors.green};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
    padding-right: 3rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-right: 10rem;
    align-items: flex-start;
  }
`;

export const StyledTitle = styled('h2')`
  font-size: 3.2rem;
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.colors.green};
  ${({ theme }) => theme.mq.lg} {
    font-size: 5.2rem;
    margin: 0 0 3rem;
  }
`;

export const StyledSubTitle = styled('h3')`
  font-size: 2.2rem;
  margin: 0 0 2rem;
  font-weight: 100;
  color: ${({ theme }) => theme.colors.green};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.2rem;
    margin: 0 0 3rem;
  }
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin: 0 0 3rem;
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 3.3rem;
    margin: 0 0 4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.4rem;
    line-height: 3.8rem;
  }
`;

export const StyledImages = styled('div')`
  width: 100%;
  height: 300px;
  position: relative;
  margin-top: 30px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: 20px;
    width: 50%;
    height: 500px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-top: 0;
    height: 800px;
  }
`;

export const StyledImagesInner = styled('div')`
  display: block;
`;

export const StyledImage = styled('div')`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.mq.lg} {
    height: 100%;
  }
`;

export const StyledImageImgSmall = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  width: 25%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 100%;
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
    left: 0;
    right: 0;
    transform: unset;
  }
`;

export const StyledImageImgBig = styled(GatsbyImage)`
  position: absolute;
  bottom: 0;
  width: 45%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 100%;
  ${({ theme }) => theme.mq.lg} {
    width: 95%;
    left: 0;
    right: 0;
    transform: unset;
  }
`;

export const StyledBtn = styled(Link)`
  width: auto;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 900;
  text-transform: lowercase;
  color: ${({ theme }) => theme.colors.green};
  transition: background-color 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:after {
    content: ' ...';
  }
`;
