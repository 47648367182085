import React, { useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { heroAnimation } from 'theme/Animations';

import Social from 'components/Social/Social';

import {
  StyledHeroBackground,
  StyledTitle,
  StyledSocial,
} from './HeaderStyles';
import HeroImage from '../../components/Header/HeroImage';

const Header = () => {
  let title = useRef(null);

  const data = useStaticQuery(graphql`
    {
      file(relativeDirectory: { eq: "Header" }, name: { eq: "baner" }) {
        publicURL
      }
      strapiFooter {
        linkedin
        facebook
        youtube
        instagram
      }
    }
  `);

  const { linkedin, facebook, youtube, instagram } = data.strapiFooter;

  const socialsData = [
    {
      id: 1,
      name: 'Linkedin',
      url: linkedin,
    },
    {
      id: 2,
      name: 'Facebook',
      url: facebook,
    },
    {
      id: 3,
      name: 'Youtube',
      url: youtube,
    },
    {
      id: 4,
      name: 'Instagram',
      url: instagram,
    },
  ];

  useEffect(() => {
    heroAnimation([title]);
    window.scrollTo(0, 0);
    document.body.style.overflowY = 'hidden';
    setTimeout(() => {
      document.body.style.overflowY = 'scroll';
    }, 2500);
  }, []);

  return (
    <StyledHeroBackground Tag="div" url={data.file.publicURL}>
      <StyledTitle
        ref={el => {
          title = el;
        }}
      >
        <HeroImage />
      </StyledTitle>
      <StyledSocial>
        <Social socialsData={socialsData} />
      </StyledSocial>
    </StyledHeroBackground>
  );
};

export default Header;
