import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../theme/theme';

const HeroImageWrapper = styled.div`
  width: ${({ width }) => width ?? '60rem'};
  @media (max-width: ${`${breakpoints.md}px`}) {
    width: ${({ width }) => width ?? '35rem'};
  }

  img {
    width: ${({ width }) => width ?? '60rem'};
    @media (max-width: ${`${breakpoints.md}px`}) {
      width: ${({ width }) => width ?? '35rem'};
    }
  }
`;

const HeroImage = props => {
  const localData = useStaticQuery(graphql`
    {
      heroImage: file(
        relativeDirectory: { eq: "logo" }
        name: { eq: "hero-title" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <HeroImageWrapper width={props.width}>
      <img
        src={localData.heroImage.childImageSharp.fluid.src}
        alt="hero-title"
      />
    </HeroImageWrapper>
  );
};

export default HeroImage;
