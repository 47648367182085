import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';
import { getImage } from 'gatsby-plugin-image';

import { aboutUsAnimation } from '../../theme/Animations';

import {
  StyledBackground,
  StyledWrapper,
  StyledHeading,
  StyledImages,
  StyledImagesInner,
  StyledImage,
  StyledImageImg,
  StyledContainer,
} from './SkinCareStyles';

const SkinCare = ({ data, currentLocale }) => {
  let container = useRef(null);
  let heading = useRef(null);
  let image = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      aboutUsAnimation(container, image, heading);
    }
  }, []);

  return (
    <StyledBackground
      Tag="div"
      fluid={data.background.localFile.childImageSharp.fluid}
      style={{
        backgroundPosition: '80% 50%',
      }}
    >
      <StyledContainer>
        <StyledWrapper
          ref={el => {
            container = el;
          }}
        >
          <StyledHeading
            ref={el => {
              heading = el;
            }}
          >
            {parse(data.title)}
          </StyledHeading>
          <StyledImages>
            <StyledImagesInner>
              <StyledImage
                onClick={() => {
                  if (data.image.caption) {
                    const links = data.image.caption.split('|');
                    window.open(
                      currentLocale === 'en' && links[1] ? links[1] : links[0],
                      '_blank'
                    );
                  }
                }}
                ref={el => {
                  image = el;
                }}
              >
                <StyledImageImg
                  image={getImage(data.image.localFile)}
                  alt={data.image.alternativeText ?? 'about_us'}
                  objectFit="contain"
                />
              </StyledImage>
            </StyledImagesInner>
          </StyledImages>
        </StyledWrapper>
      </StyledContainer>
    </StyledBackground>
  );
};

export default SkinCare;
