import React, { useRef, useEffect } from 'react';

import parse from 'html-react-parser';
import { useIntl } from 'gatsby-plugin-intl';
import { isBrowser } from 'react-device-detect';
import { aboutUsAnimation } from 'theme/Animations';
import { getImage } from 'gatsby-plugin-image';

import {
  StyledTwoProducts,
  StyledContainer,
  StyledTitle,
  StyledImage,
  StyledImageImgBig,
  StyledImageImgSmall,
  StyledImages,
  StyledImagesInner,
  StyledContent,
  StyledParagraph,
  StyledWrapper,
  StyledBtn,
  StyledSubTitle,
} from './TwoProductsStyles';

const TwoProducts = ({ data, currentLocale }) => {
  const intl = useIntl();
  let container = useRef(null);
  let content2 = useRef(null);
  let content3 = useRef(null);
  let image2 = useRef(null);
  let image3 = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      aboutUsAnimation(container, image2, content2);
      aboutUsAnimation(container, image3, content3);
    }
  }, []);
  return (
    <StyledTwoProducts
      ref={el => {
        container = el;
      }}
    >
      <StyledContainer>
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledWrapper>
          <StyledWrapper>
            <StyledContent
              ref={el => {
                content2 = el;
              }}
            >
              <StyledSubTitle>{parse(data.title2)}</StyledSubTitle>
              <StyledParagraph>{parse(data.text2)}</StyledParagraph>
              <StyledBtn to={`${data.link2}`}>
                {intl.formatMessage({ id: 'read_more' })}
              </StyledBtn>
            </StyledContent>
            <StyledImages>
              <StyledImagesInner>
                <StyledImage
                  onClick={() => {
                    if (data.image2.caption) {
                      const links = data.image2.caption.split('|');
                      window.open(
                        currentLocale === 'en' && links[1]
                          ? links[1]
                          : links[0],
                        '_blank'
                      );
                    }
                  }}
                  ref={el => {
                    image2 = el;
                  }}
                >
                  <StyledImageImgBig
                    image={getImage(data.image2.localFile)}
                    alt={data.image2.alternativeText ?? 'bota_retinol'}
                    objectFit="contain"
                  />
                </StyledImage>
              </StyledImagesInner>
            </StyledImages>
          </StyledWrapper>
          <StyledWrapper>
            <StyledContent
              ref={el => {
                content3 = el;
              }}
            >
              <StyledSubTitle>{parse(data.title3)}</StyledSubTitle>
              <StyledParagraph>{parse(data.text3)}</StyledParagraph>
              <StyledBtn to={`${data.link3}`}>
                {intl.formatMessage({ id: 'read_more' })}
              </StyledBtn>
            </StyledContent>
            <StyledImages>
              <StyledImagesInner>
                <StyledImage
                  onClick={() => {
                    if (data.image3.caption) {
                      const links = data.image3.caption.split('|');
                      window.open(
                        currentLocale === 'en' && links[1]
                          ? links[1]
                          : links[0],
                        '_blank'
                      );
                    }
                  }}
                  ref={el => {
                    image3 = el;
                  }}
                >
                  <StyledImageImgSmall
                    image={getImage(data.image3.localFile)}
                    alt={data.image3.alternativeText ?? 'bota_retinol'}
                    objectFit="contain"
                  />
                </StyledImage>
              </StyledImagesInner>
            </StyledImages>
          </StyledWrapper>
        </StyledWrapper>
      </StyledContainer>
    </StyledTwoProducts>
  );
};

export default TwoProducts;
