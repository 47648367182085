import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-color: #006c68;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mq.lg} {
  }
`;

export const StyledContainer = styled('div')`
  max-width: 600px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
`;

export const StyledWrapper = styled('div')`
  padding: 12.8rem 2.5rem 4rem;
  overflow: hidden;
  ${({ theme }) => theme.mq.sm} {
    padding: 12.8rem 2.5rem 4rem;
  }
  ${({ theme }) => theme.mq.smm} {
    padding: 12.8rem 2.5rem 4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 12.8rem 2.5rem 4rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 12.8rem 2.5rem 4rem;
  }
`;

export const StyledHeading = styled('h2')`
  color: white;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 3.5rem;
  text-transform: capitalize;
  text-align: left;
  ${({ theme }) => theme.mq.md} {
    font-size: 4.2rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5.2rem;
  }
`;

export const StyledImages = styled('div')`
  width: 100%;
  height: 55vh;
  position: relative;
  margin-top: 30px;
  ${({ theme }) => theme.mq.xxl} {
    margin-top: 30px;
    height: 85vh;
`;

export const StyledImagesInner = styled('div')`
  display: block;
`;

export const StyledImage = styled('div')`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 95%;
  height: 70%;
  padding-right: 5%;
`;

export const StyledImageImg = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  cursor: pointer;
`;
