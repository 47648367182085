import styled from 'styled-components';

export const StyledFilm = styled('video')`
  width: 100%;
  display: block;
`;

export const Desktop = styled('div')`
  display: none;

  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`;
