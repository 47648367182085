import React from 'react';
import withLocale from 'hoc/withLocale';
import { StyledFilm, Desktop } from './FilmStyles';

const Film = ({ data }) => (
  <Desktop>
    <StyledFilm
      autoPlay
      muted
      loop
      src={process.env.GATSBY_API_URL + data.film.url}
    />
  </Desktop>
);

export default withLocale(Film);
