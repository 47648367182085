import React, { useRef, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { isBrowser } from 'react-device-detect';

import { aboutUsAnimation } from 'theme/Animations';
import { getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

import {
  StyledAboutUs,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledTitle,
  StyledParagraph,
  StyledImages,
  StyledImagesInner,
  StyledImage,
  StyledImageImg,
  StyledBtn,
} from './AboutUsStyles';

const AboutUs = ({ data, currentLocale }) => {
  const intl = useIntl();

  let container = useRef(null);
  let content = useRef(null);
  let image = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      aboutUsAnimation(container, image, content);
    }
  }, []);

  return (
    <StyledAboutUs
      ref={el => {
        container = el;
      }}
    >
      <StyledContainer>
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledWrapper>
          <StyledContent
            ref={el => {
              content = el;
            }}
          >
            <StyledParagraph>{parse(data.text)}</StyledParagraph>
            <StyledBtn to={`/${data.link}`}>
              {intl.formatMessage({ id: 'read_more' })}
            </StyledBtn>
          </StyledContent>
          <StyledImages>
            <StyledImagesInner>
              <StyledImage
                onClick={() => {
                  if (data.image.caption) {
                    const links = data.image.caption.split('|');
                    window.open(
                      currentLocale === 'en' && links[1] ? links[1] : links[0],
                      '_blank'
                    );
                  }
                }}
                ref={el => {
                  image = el;
                }}
              >
                <StyledImageImg
                  image={getImage(data.image.localFile)}
                  alt={data.image.alternativeText ?? 'about_us'}
                />
              </StyledImage>
            </StyledImagesInner>
          </StyledImages>
        </StyledWrapper>
      </StyledContainer>
    </StyledAboutUs>
  );
};

export default AboutUs;
